import { PayloadAction, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { IRootState } from "app/_contexts/ReduxProvider";
import { M } from "app/_types/Schema";

const directoryConfigurationAdapter = createEntityAdapter({
   selectId: (directoryConfiguration: M.DirectoryConfiguration) => directoryConfiguration.name
   // sortComparer: (a, b) => a.id - b.id
});

const initialState = directoryConfigurationAdapter.getInitialState<{
   selectedDirectoryConfigurationName: string | null;
   error: string | null;
   revalidate: string;
}>({
   selectedDirectoryConfigurationName: null,
   error: null,
   revalidate: ""
});

export const directoryConfigurationSlice = createSlice({
   name: "directoryConfiguration",
   initialState,
   reducers: {
      addDirectoryConfiguration: directoryConfigurationAdapter.addOne,
      removeDirectoryConfiguration: directoryConfigurationAdapter.removeOne,
      addDirectoryConfigurations: directoryConfigurationAdapter.addMany,
      setDirectoryConfigurations: directoryConfigurationAdapter.setMany,
      upsertDirectoryConfigurations: directoryConfigurationAdapter.upsertMany,
      upsertDirectoryConfiguration: directoryConfigurationAdapter.upsertOne,
      updateDirectoryConfigurations: directoryConfigurationAdapter.updateMany,
      updateDirectoryConfiguration: directoryConfigurationAdapter.updateOne,
      removeAllDirectoryConfigurations: directoryConfigurationAdapter.removeAll,
      addChildItems: (state, action: PayloadAction<{ name: string; items: string[] }>) => {
         const { name, items } = action.payload;
         const existingItem = state.entities[name];

         if (existingItem) {
            const { items: existingItems } = existingItem;
            const newItems = items.filter((argsI) => !existingItems.find((ei) => ei === argsI));
            existingItem.items = [...existingItems, ...newItems];
         }
      },
      removeChildItems: (state, action: PayloadAction<{ name: string; items: string[] }>) => {
         const { name, items } = action.payload;
         const existingItem = state.entities[name];
         if (existingItem) {
            const { items: existingItems } = existingItem;
            const newItems = existingItems.filter((argsI) => !items.find((ei) => ei === argsI));
            existingItem.items = newItems;
         }
      },
      setSelectedDirectoryConfigurationName(state, action: PayloadAction<string>) {
         state.selectedDirectoryConfigurationName = action.payload;
      },
      setRevalidate(state, action: PayloadAction<null>) {
         state.revalidate = new Date().toISOString();
      }
   }
});

export const directoryConfigurationActions = directoryConfigurationSlice.actions;

export const selectDirectoryConfigurationName = createSelector(
   [(state: IRootState) => state.directoryConfiguration],
   (directoryConfigurationState) => directoryConfigurationState.selectedDirectoryConfigurationName
);
export const selectDirectoryConfigurationRevalidate = createSelector([(state: IRootState) => state.directoryConfiguration], (directoryConfigurationState) => directoryConfigurationState.revalidate);
export const {
   selectAll: selectAllDirectoryConfigurations,
   selectById: selectDirectoryConfigurationById,
   selectIds: selectDirectoryConfigurationIds,
   selectEntities: selectDirectoryConfigurationEntities
} = directoryConfigurationAdapter.getSelectors<IRootState>((state) => state.directoryConfiguration);
