import ILog from "app/_lib/global/Log";
import { PA } from "app/_types/PATypes";
import { useMemo } from "react";
import useAllCCQueries from "./useAllCCQueries";

export interface UseAllRoleConditionalsProps {
   campaignId?: string | undefined;
   teamId?: string | undefined;
   attemptId?: string | undefined;
   skillsetId?: string | undefined;
   order?: AcronymType[] | undefined;
   include?: AcronymType[] | undefined;
   conditional?: boolean | undefined;
}

export type AcronymType = keyof typeof RoleAcronyms;
export enum RoleAcronyms {
   c = "connection",
   h = "host",
   o = "owner",
   cm = "campaign_manager",
   p = "public",
   a = "authenticated",
   qs = "qualification_set_control_manager",
   aam = "activity_attempt_manager",
   aac = "activity_attempt_contributor",
   acm = "activity_control_manager",
   dcm = "dashboard_control_manager"
}
export enum RoleAcronymsReverse {
   connection = "c",
   host = "h",
   owner = "o",
   campaign_manager = "cm",
   public = "p",
   authenticated = "a",
   qualification_set_control_manager = "qs",
   activity_attempt_manager = "aam",
   activity_attempt_contributor = "aac",
   activity_control_manager = "acm"
}
export default function useAllRoleConditionals({ teamId, attemptId, skillsetId, campaignId, order, include, conditional: conditionalParams }: UseAllRoleConditionalsProps) {
   const { teamManagerRole, attemptManagerRole, attemptContributorRole, skillsetManagerRole, authenticatedRole, campaignManagerRole, connectionRole, hostRole, ownerRole, publicRole } =
      useAllCCQueries({ campaignId, teamId, attemptId, skillsetId });
   const { conditional, roles } = useMemo(() => {
      let availableRoles = [
         teamManagerRole,
         attemptManagerRole,
         attemptContributorRole,
         skillsetManagerRole,
         authenticatedRole,
         campaignManagerRole,
         connectionRole,
         hostRole,
         ownerRole,
         publicRole
      ].filter((role) => !!role);
      let finalRoles: PA.RoleQueries[] = [];
      if (order) {
         order.forEach((roleKey) => {
            const role = availableRoles.find((r) => {
               if (!r || !r?.type) return false;
               const key = RoleAcronyms[roleKey];

               ILog.v("useUniversalRoleConditionals1", { roleKey, availableRoles, order, finalRoles, key, r, bool: r.type === key });
               return r.type === key;
            });
            if (!!role) {
               finalRoles.push(role);
            }
         });
      }
      order && ILog.v("useUniversalRoleConditionals2", { order, availableRoles, finalRoles });
      if (include) {
         finalRoles = availableRoles.filter((role) => {
            if (role === undefined) return false;
            const roleType = role?.enumKey;
            if (roleType === undefined) return false;
            const roleKey = roleType;
            include.includes(roleKey) && ILog.v("useUniversalRoleConditionals2_1", { order, availableRoles, finalRoles, roleKey, include, bool: include.includes(roleKey) });

            return Boolean(include.includes(roleKey));
         }) as PA.RoleQueries[];
      } else {
         availableRoles.forEach((role) => {
            if (role === undefined) return;
            if (!finalRoles.includes(role)) {
               finalRoles.push(role);
            }
         });
      }
      finalRoles.length > 0 && ILog.v("useUniversalRoleConditionals3", { order, finalRoles, include, availableRoles });

      let finalConditional = finalRoles.some((role) => !!role);
      if (conditionalParams !== undefined) {
         finalConditional = conditionalParams;
      }
      // finalConditional === false && ILog.verbose( "useUniversalRoleConditionals4", { finalRoles, conditionalParams, finalConditional });
      ILog.v("useUniversalRoleConditionals4", { finalRoles, conditionalParams, finalConditional });
      return {
         conditional: finalConditional,
         roles: finalRoles
      };
   }, [
      teamManagerRole,
      attemptManagerRole,
      attemptContributorRole,
      skillsetManagerRole,
      authenticatedRole,
      campaignManagerRole,
      connectionRole,
      hostRole,
      ownerRole,
      publicRole,
      order,
      include,
      campaignId,
      teamId,
      attemptId,
      skillsetId,
      conditionalParams
   ]);
   ILog.v("useUniversalRoleConditionals5", { conditional, roles, aac: roles?.find((r) => r?.type === "activity_attempt_contributor") });
   return {
      conditional,
      roles
   };
}
