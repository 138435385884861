import ILog from "app/_lib/global/Log";
import { NextServerAction } from "app/_services/redux/api/apiNext";
import { TAGS } from "../../../_services/redux/api/TAGS";
import { handleRTKError, handleRTKTags } from "../../../_services/redux/api/_helpers/rtkHelpers";
import { apiService } from "../../../_services/redux/api/apiService";
import refetchAuthenticatedSession from "../_actions/refetch";

export const apiAuthenticatedSession = apiService.injectEndpoints({
   endpoints: (builder) => ({
      getRefreshCookie: builder.query<Awaited<NextServerAction<{ expires: number }>>, {}>({
         queryFn: async (arg, api) => {
            try {
               ILog.v("apiAuthenticatedSession.getRefreshCookie", {});
               const res = await refetchAuthenticatedSession();
               if (res?.data?.expires) {
                  return { data: res };
               } else {
                  ILog.e("apiAuthenticatedSession.getRefreshCookie", { res });
                  throw new Error("AUTHENTICATED_ERROR");
               }
            } catch (error: any) {
               ILog.v("apiAuthenticatedSession.getRefreshCookie", { error });
               return handleRTKError({ error, endpoint: "getRefreshCookie" });
            }
         },

         providesTags: (result, error, query) => {
            return handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               resultTags: result ? [{ type: TAGS.AUTHENTICATED_SESSION, id: "SINGLETON" }] : [{ type: TAGS.AUTHENTICATED_ERROR, id: "SINGLETON" }]
            });
         }
      })
   }),

   overrideExisting: true
});
