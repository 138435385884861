import { UserIdContext } from "app/(platform)/(authentication)/_contexts/AuthenticatedSessionProvider";
import useAuthenticatedPublicRoles from "app/(platform)/(authentication)/_hooks/useAuthenticatedPublicRoles";
import { apiWSAuthenticated } from "app/(platform)/@search/_api/apiWSAuthenticated";
import { CampaignContext } from "app/(platform)/_contexts/CampaignContext";
import ILog from "app/_lib/global/Log";
import { calculateCCQueryDefaults } from "app/_services/redux/api/_helpers/rtkHelpers";
import { PA } from "app/_types/PATypes";
import { M } from "app/_types/Schema";
import { useParams } from "next/navigation";
import { useContext, useMemo } from "react";
import useAllCDUQueries from "./useAllCDUQueries";
import { RoleAcronymsReverse } from "./useAllRoleConditionals";
export default function useAllCCQueries({
   campaignId,
   teamId,
   attemptId,
   skillsetId,
   log
}: {
   campaignId?: string | undefined;
   teamId?: string | undefined | null;
   attemptId?: string | undefined | null;
   skillsetId?: string | undefined;
   log?: boolean;
}): {
   authenticatedRole: PA.RoleQueries | undefined;
   publicRole: PA.RoleQueries | undefined;
   teamManagerRole: PA.RoleQueries | undefined;
   attemptManagerRole: PA.RoleQueries | undefined;
   attemptContributorRole: PA.RoleQueries | undefined;
   skillsetManagerRole: PA.RoleQueries | undefined;
   connectionRole: PA.RoleQueries | undefined;
   hostRole: PA.RoleQueries | undefined;
   campaignManagerRole: PA.RoleQueries | undefined;
   ownerRole: PA.RoleQueries | undefined;
} {
   const { campaignId: campaignIdContext } = useContext(CampaignContext);
   const campaignIdParams = useParams().campaign_id as string | undefined;
   const finalCampaignId = campaignId || campaignIdContext || campaignIdParams;
   const teamIdParams = useParams().team_id as string | undefined;
   const finalTeamId = teamId || teamIdParams;
   const attemptIdParams = useParams().attempt_id as string | undefined;
   const finalAttemptId = attemptId || attemptIdParams;
   const skillsetIdParams = useParams().skillset_id as string | undefined;
   const finalSkillsetId = skillsetId || skillsetIdParams;
   const { authenticatedRole, publicRole } = useAuthenticatedPublicRoles();

   const controlManagerRole = useCCRoleQuery({
      campaignId: finalCampaignId,
      teamId: finalTeamId,
      roleType: "activity_control_manager",
      log
   });

   const attemptManagerRole = useCCRoleQuery({
      campaignId: finalCampaignId,
      teamId: finalTeamId,
      attemptId: finalAttemptId,
      roleType: "activity_attempt_manager",
      log
   });

   const attemptContributorRole = useCCRoleQuery({
      campaignId: finalCampaignId,
      teamId: finalTeamId,
      attemptId: finalAttemptId,
      roleType: "activity_attempt_contributor",
      log
   });

   const skillsetManagerRole = useCCRoleQuery({
      teamId: undefined,
      campaignId: finalCampaignId,
      skillsetId: finalSkillsetId,
      roleType: "qualification_set_control_manager",
      log: false
   });
   const { connectionRole, hostRole, campaignManagerRole, ownerRole } = useAllCDUQueries({ campaignId: finalCampaignId });
   log &&
      ILog.v("useAllCCQueries", {
         campaignId,
         teamId,
         attemptId,
         skillsetId,
         finalCampaignId,
         finalTeamId,
         finalAttemptId,
         finalSkillsetId,
         controlManagerRole,
         attemptManagerRole,
         attemptContributorRole,
         skillsetManagerRole,
         connectionRole,
         hostRole,
         campaignManagerRole,
         ownerRole
      });
   return {
      publicRole,
      authenticatedRole,
      teamManagerRole: controlManagerRole as PA.RoleQueries | undefined,
      attemptManagerRole: attemptManagerRole as PA.RoleQueries | undefined,
      attemptContributorRole: attemptContributorRole as PA.RoleQueries | undefined,
      skillsetManagerRole: skillsetManagerRole as PA.RoleQueries | undefined,
      connectionRole,
      hostRole,
      campaignManagerRole,
      ownerRole
   };
}

function useCCRoleQuery({
   campaignId,
   teamId,
   attemptId,
   skillsetId,
   roleType,
   log
}: {
   campaignId: string | undefined;
   teamId?: string | undefined;
   attemptId?: string | undefined;
   skillsetId?: string | undefined;
   roleType: M.CommitmentConnection["commitment_connection_type"] | undefined;
   log?: boolean;
}): PA.RoleQueries | undefined {
   const { userId } = useContext(UserIdContext);
   const { authenticatedRole } = useAuthenticatedPublicRoles();

   // const selectCC = useMemo(() => {
   //    // Return a unique selector instance for this page so that
   //    // the filtered results are correctly memoized
   //    return createSelector(
   //       (res) => res.data,
   //       (res, campaignId) => campaignId,
   //       (res, campaignId, teamId) => teamId,
   //       (res, campaignId, teamId, attemptId) => attemptId,
   //       (res, campaignId, teamId, attemptId, skillsetId) => skillsetId,
   //       (res, campaignId, teamId, attemptId, skillsetId, roleType) => roleType,
   //       (data: M.CommitmentConnection[], campaignId, teamId, attemptId, skillsetId, roleType) => {
   //          return data?.find((cc) => {
   //             let bool =
   //                cc.commitment_connection_campaign === campaignId &&
   //                cc.commitment_connection_type === roleType &&
   //                cc.commitment_connection_directus_users === userId &&
   //                cc.commitment_connection_void === false;
   //             if (teamId) {
   //                bool = bool && cc.commitment_connection_activity_control === teamId;
   //             }
   //             if (attemptId) {
   //                bool = bool && cc.commitment_connection_activity_attempt === attemptId;
   //             }
   //             if (skillsetId) {
   //                bool = bool && cc.commitment_connection_qualification_set_control === skillsetId;
   //             }

   //              ILog.v("useCCRoleQuery", { data, bool, cc, campaignId, teamId, attemptId, skillsetId, roleType });
   //             return bool;
   //          });
   //       }
   //    );
   // }, [campaignId, teamId, attemptId, skillsetId, roleType, userId]);

   const query = useMemo(() => {
      if (campaignId && userId && roleType) {
         return calculateCCQueryDefaults({ campaignId, userId, roleType, skillsetId, teamId, attemptId });
      } else {
         log && ILog.v("useCCRoleQuery -- NO QUERY", { campaignId, userId, roleType, teamId, attemptId, skillsetId });
      }
      return undefined;
   }, [campaignId, userId, roleType, teamId, attemptId, skillsetId]);
   const { data: roleCC } = apiWSAuthenticated.useMyCCQuery(
      {
         role: authenticatedRole,
         // query: { filter: { commitment_connection_directus_users: { _eq: userId } } }
         query
      },
      {
         // selectFromResult: (result) => ({
         //    // We can optionally include the other metadata fields from the result here
         //    ...result,
         //    // Include a field called `postsForUser` in the hook result object,
         //    // which will be a filtered list of posts
         //    roleCC: selectCC(result, campaignId, teamId, attemptId, skillsetId, roleType)
         // }),
         skip: !campaignId || !roleType || !authenticatedRole || !query
      }
   );

   const role = useMemo(() => {
      if (!authenticatedRole) return undefined;
      if (roleCC && roleCC?.length > 0 && roleType && query) {
         const roleId = roleCC[0].commitment_connection_directus_roles[0] as string | undefined;
         !roleId && log && ILog.v("useCCRoleQuery -- NO ROLEID", { roleId, roleCC, roleType, query, teamId, attemptId, skillsetId, campaignId, userId });
         return { enumKey: RoleAcronymsReverse[roleType as keyof typeof RoleAcronymsReverse], type: roleType, CC: query, roleId };
      } else {
         log && ILog.v("useCCRoleQuery -- NO ROLE", { roleCC, roleType, query, teamId, attemptId, skillsetId, campaignId, userId });
      }
      return undefined;
   }, [roleCC, roleType, query, teamId, attemptId, skillsetId, campaignId, userId, authenticatedRole]);
   log && ILog.v("useCCRoleQuery", { role, roleCC, query, skip: !campaignId || !roleType || !authenticatedRole || !query, campaignId, roleType, authenticatedRole });
   return role;
}
