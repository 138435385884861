import { Query } from "@directus/sdk";
import { getMySession } from "app/(platform)/(authentication)/_helpers/getMySession";
import { tryDirectusWS } from "app/_lib/directus/ws";
import ILog from "app/_lib/global/Log";
import { TAGS } from "app/_services/redux/api/TAGS";
import { PA } from "app/_types/PATypes";
import { M } from "app/_types/Schema";
import { handleRTKTags } from "../../../_services/redux/api/_helpers/rtkHelpers";
import { apiService } from "../../../_services/redux/api/apiService";

export const apiWSAuthenticated = apiService.injectEndpoints({
   endpoints: (builder) => ({
      myCDU: builder.query<
         M.CampaignDirectusUser[],
         {
            token?: string | undefined;
            role: PA.RoleQueries | undefined;
            query?: Query<M.CustomDirectusTypes, M.CampaignDirectusUser[]>;
         }
      >({
         queryFn: async (arg, api, extraOptions, baseQuery) => {
            const { token, roleId, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
            const userId = authenticatedSession?.user?.id;
            if (!roleId || !userId || !arg.role) throw new Error("apiWS no token or userId found");
            // const client = (await WS_NAMESPACED({ roleQuery: arg.role }))?.client;
            // ILog.v("listenForCollectionEvents_OPENING_WS", { token: arg.token });

            return { data: [] };
         },

         async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getCacheEntry, requestId, dispatch }) {
            const { token, roleId, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch });
            const userId = authenticatedSession?.user?.id;
            if (!roleId || !userId || !arg.role) throw new Error("apiWS no token or userId found");

            await tryDirectusWS<M.CampaignDirectusUser>({
               dispatch,
               cacheDataLoaded,
               role: arg.role,
               getCacheEntry,
               collection: "campaign_directus_user",
               query: arg.query || { filter: { campaign_directus_user_user: { _eq: userId } } },
               onUpdateCachedData(message) {
                  const { type, data, uid, event, status } = message;

                  if (event === "init" && data?.length > 0) {
                     ILog.v("myCDU_updateCachedData_INIT", { data });

                     updateCachedData((draft) => {
                        ILog.v("myCDU_updateCachedData_INIT_INSERT_ALL", { draft, data });

                        return data;
                     });
                  } else if (event === "create") {
                     data.forEach((d) => {
                        updateCachedData((draft) => {
                           ILog.v("myCDU_updateCachedData_POSTINIT_CREATE", { uid, data, type, event, d });
                           draft?.push(d);
                        });
                     });
                  } else if (event === "update") {
                     updateCachedData((draft) => {
                        const updated = data;
                        // entries should not include any entries with the same id as the updated array
                        let entries = draft?.filter((d) => !updated.some((u) => u.id === d.id));
                        entries?.push(...updated);
                        ILog.v("myCDU_updateCachedData_POSTINIT_UPDATE", { uid, data, type, event, entries });
                        return entries;
                     });
                  } else {
                     ILog.v("myCDU_updateCachedData_POSTINIT_OTHER", { uid, data, type, event });
                  }
               },
               uidArgs: userId,
               cacheEntryRemoved
            });

            ILog.v("listenForCollectionEvents_apiWS_CLOSING_WS", {});
         },
         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.CAMPAIGN_DIRECTUS_USER }]
            })
      }),
      myCC: builder.query<
         M.CommitmentConnection[],
         {
            token?: string | undefined;
            role: PA.RoleQueries | undefined;
            query?: Query<M.CustomDirectusTypes, M.CommitmentConnection[]>;
         }
      >({
         queryFn: async (arg, api, extraOptions, baseQuery) => {
            if (!arg.role) throw new Error("apiWS no role found");
            // const client = (await WS_NAMESPACED({ roleQuery: arg.role }))?.client;
            // ILog.v("listenForCollectionEvents_OPENING_WS", { token: arg.token });

            return { data: [] };
         },

         async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getCacheEntry, dispatch }) {
            ILog.v("listenForCollectionEvents_apiWS_OPENING_WS", { token: arg.token });

            const { authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch });
            const userId = authenticatedSession?.user?.id;
            if (!userId) throw new Error("apiWS no token or userId found");
            await tryDirectusWS<M.CommitmentConnection>({
               dispatch,
               cacheDataLoaded,
               role: arg.role,
               getCacheEntry,
               collection: "commitment_connection",
               query: arg.query || { filter: { commitment_connection_directus_users: { _eq: userId } } },
               onUpdateCachedData(message) {
                  const { type, data, uid, event, status } = message;
                  if (event === "init" && data?.length > 0) {
                     updateCachedData((draft) => {
                        ILog.v("allMyCCs_updateCachedData_INIT_INSERT_ALL", { draft, data });
                        return data;
                     });
                  } else if (event === "create") {
                     data.forEach((d) => {
                        updateCachedData((draft) => {
                           ILog.v("allMyCCs_updateCachedData_POSTINIT_CREATE", { uid, data, type, event, d });
                           draft?.push(d);
                        });
                     });
                  } else if (event === "update") {
                     updateCachedData((draft) => {
                        const updated = data;
                        let entries = draft?.filter((d) => !updated.some((u) => u.id === d.id));
                        entries?.push(...updated);
                        ILog.v("allMyCCs_updateCachedData_POSTINIT_UPDATE", { uid, data, type, event, entries });
                        return entries;
                     });
                  }
               },
               uidArgs: userId,
               cacheEntryRemoved
            });
         },
         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.COMMITMENT_CONNECTION }]
            })
      })
   }),
   overrideExisting: "throw"
});
