"use client";
import { apiWSAuthenticated } from "app/(platform)/@search/_api/apiWSAuthenticated";
import ILog from "app/_lib/global/Log";
import { TAGS } from "app/_services/redux/api/TAGS";
import { apiNext } from "app/_services/redux/api/apiNext";
import { apiService } from "app/_services/redux/api/apiService";
import { Sessions } from "app/_types/Session";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect, useMemo, useState } from "react";
import { apiAuthenticatedSession } from "../_api/authenticatedSession";
import getAuthenticatedSessionClientside from "../_helpers/getAuthenticatedSessionClientside";
import useAuthenticatedPublicRoles from "./useAuthenticatedPublicRoles";
import { useAppStore } from "app/_contexts/ReduxProvider";

export default function useAuth(params: { required: boolean }) {
   const router = useRouter();
   const searchParams = useSearchParams();
   const callbackUrl = searchParams?.get("callback");
   const [session, setSession] = useState<Sessions.Authenticated.Session | undefined>(getAuthenticatedSessionClientside());
   const { authenticatedRole } = useAuthenticatedPublicRoles();
   const [signIn, { isSuccess: signInIsSuccess, isUninitialized: signInIsUninitialized }] = apiNext.useActionMutation({ fixedCacheKey: `sign-in-cache` });
   const [signOut, { isSuccess: signOutIsSuccess, reset: signOutReset, isUninitialized: signOutIsUninitialized }] = apiNext.useActionMutation({ fixedCacheKey: `sign-out-cache` });

   const refresh = apiAuthenticatedSession.useGetRefreshCookieQuery({}, { skip: !session?.access_token });

   const cduWS = apiWSAuthenticated.useMyCDUQuery({ role: authenticatedRole }, { skip: !authenticatedRole });
   const ccWS = apiWSAuthenticated.useMyCCQuery({ role: authenticatedRole }, { skip: !authenticatedRole });
   const store = useAppStore();
   ILog.v("useAuth", { cduWS, ccWS, skip: !session?.access_token, refresh });
   const { status } = useMemo<{ status: "authenticated" | "loading" | "unauthenticated" | undefined }>(() => {
      if (session) {
         return {
            status: "authenticated"
         };
      } else if (refresh.isLoading && !session) {
         return {
            status: "loading"
         };
      } else if (session === undefined || (signInIsUninitialized && refresh?.isUninitialized)) {
         return {
            status: "unauthenticated"
         };
      } else {
         return {
            status: undefined
         };
      }
   }, [session?.access_token, session?.expires, session?.user?.id, refresh?.isLoading, session, refresh?.isUninitialized, refresh?.data, signInIsUninitialized]);
   useEffect(() => {
      if (refresh?.isError || signOutIsSuccess) {
         ILog.v("useAuth_ setSession(undefined)", { signOutIsSuccess });
         setSession(undefined);

         if (signOutIsSuccess) {
            signOutReset();
         }
      }
   }, [refresh?.isError, signOutIsSuccess]);

   useEffect(() => {
      if (refresh?.isSuccess || signInIsSuccess) {
         const cookieSession = getAuthenticatedSessionClientside();
         ILog.v("useAuth_refreshIsSuccess", { cookieSession });
         if (cookieSession?.access_token !== undefined) {
            setSession(cookieSession);
            store.dispatch(apiService.util.invalidateTags([{ type: TAGS.AUTHENTICATED_SESSION, id: "MAIN" }]));
         }
      }
   }, [refresh?.isSuccess, refresh?.data, signInIsSuccess, refresh?.isError, refresh?.isError, signInIsUninitialized]);

   useEffect(() => {
      if (callbackUrl && status === "authenticated") {
         ILog.v("useAuth", { callbackUrl });
         router.push(callbackUrl);
      }
   }, [status, callbackUrl, router]);

   ILog.v("useAuth", {
      status,
      authenticatedSession: session,
      signInIsSuccess,
      signInIsUninitialized,
      signOutIsSuccess,

      cduWS,
      ccWS
   });
   return {
      status,
      authenticatedSession: session,
      first_name: session?.user?.first_name,
      last_name: session?.user?.last_name,
      fullName: `${session?.user?.first_name} ${session?.user?.last_name}`
   };
}
