import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import getAuthenticatedSessionClientside from "app/(platform)/(authentication)/_helpers/getAuthenticatedSessionClientside";
import { IRootState } from "app/_contexts/ReduxProvider";
import { Directus } from "app/_lib/directus/DirectusRest";
import { Endpoints } from "app/_lib/global/Endpoints";
import ILog from "app/_lib/global/Log";
import { M } from "app/_types/Schema";
import { directoryConfigurationActions, selectAllDirectoryConfigurations } from "../slices/directoryConfiguration";
import { directoryItemActions, selectAllDirectoryItems } from "../slices/directoryItem";
import { directoryItemLabelActions, selectAllDirectoryItemLabels } from "../slices/directoryItemLabel";

export const directoryListener = createListenerMiddleware();

const matches = [
   directoryConfigurationActions.addChildItems.match,
   directoryConfigurationActions.addDirectoryConfiguration.match,
   directoryConfigurationActions.addDirectoryConfigurations.match,
   directoryConfigurationActions.removeChildItems.match,
   directoryConfigurationActions.removeDirectoryConfiguration.match,
   // directoryConfigurationActions.setDirectoryConfigurations.match,
   directoryConfigurationActions.updateDirectoryConfiguration.match,
   directoryConfigurationActions.updateDirectoryConfigurations.match,
   directoryConfigurationActions.upsertDirectoryConfiguration.match,
   directoryConfigurationActions.upsertDirectoryConfigurations.match,
   directoryItemActions.addChildItems.match,
   directoryItemActions.addDirectoryItem.match,
   directoryItemActions.addDirectoryItems.match,
   directoryItemActions.removeChildItems.match,
   directoryItemActions.removeDirectoryItem.match,
   // directoryItemActions.setDirectoryItems.match,
   directoryItemActions.updateDirectoryItem.match,
   directoryItemActions.updateDirectoryItems.match,
   directoryItemActions.upsertDirectoryItem.match,
   directoryItemActions.upsertDirectoryItems.match,
   directoryItemLabelActions.addDirectoryItemLabel.match,
   directoryItemLabelActions.addDirectoryItemLabels.match,
   directoryItemLabelActions.removeDirectoryItemLabel.match,
   directoryItemLabelActions.removeDirectoryItemLabels.match,
   // directoryItemLabelActions.setDirectoryItemLabels.match,
   directoryItemLabelActions.updateDirectoryItemLabelKey.match,
   directoryItemLabelActions.updateDirectoryItemLabelLabel.match,
   directoryItemLabelActions.updateDirectoryItemLabel.match,
   directoryItemLabelActions.updateDirectoryItemLabels.match,
   directoryItemLabelActions.upsertDirectoryItemLabel.match,
   directoryItemLabelActions.upsertDirectoryItemLabels.match
];
const isDirectoryAction = isAnyOf(...matches);

directoryListener.startListening({
   matcher: isDirectoryAction,
   effect: async (action, listenerApi) => {
      // cancel other directoryListener effects so we debounce
      ILog.v("directoryListener", { action, listenerApi });
      listenerApi.cancelActiveListeners();

      const task = listenerApi.fork(async (forkApi) => {
         await forkApi.delay(300);

         const session = getAuthenticatedSessionClientside();
         if (!session) return { status: "ok" };
         const state = listenerApi.getState() as IRootState;
         const allConfigs = selectAllDirectoryConfigurations(state);
         const allItems = selectAllDirectoryItems(state);
         const allLabels = selectAllDirectoryItemLabels(state);
         const customConfigs = allConfigs.filter((config) => config.name !== "Default" && config.name !== "default");
         const customItems = allItems.filter((item) => item.parentConfig !== "Default" && item.parentConfig !== "default");
         const customLabels = allLabels.filter((label) => {
            return label.parentConfig !== "Default" && label.parentConfig !== "default";
         });

         const DirectusUsersDirectoryBuilder: M.DirectusUsers["directus_users_directory_builder"] = {
            configs: customConfigs,
            items: customItems,
            labels: customLabels
         };

         ILog.v("directoryListener", { DirectusUsersDirectoryBuilder });

         await Directus.patch<M.DirectusUsers>({
            endpoint: Endpoints.Auth.Users,
            token: session?.access_token,
            id: session?.user.id,
            payload: {
               // data: {
               directus_users_directory_builder: DirectusUsersDirectoryBuilder
               // }
            }
         });

         return { status: "ok" };
      });

      const result = await task.result;
      if (result.status === "ok") {
      }
   }
});
