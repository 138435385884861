import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { M } from "app/_types/Schema";
import { selectDirectoryItemById } from "./directoryItem";
import { IRootState } from "app/_contexts/ReduxProvider";

const directoryItemLabelAdapter = createEntityAdapter({
   selectId: (directoryItemLabel: M.DirectoryItemLabel) => directoryItemLabel.id,
   sortComparer: (a, b) => a.sort - b.sort
});

const initialState = directoryItemLabelAdapter.getInitialState({
   status: "idle",
   error: null
});

export const directoryItemLabelSlice = createSlice({
   name: "directoryItemLabel",
   initialState,
   reducers: {
      addDirectoryItemLabel: directoryItemLabelAdapter.addOne,
      removeDirectoryItemLabel: directoryItemLabelAdapter.removeOne,
      removeDirectoryItemLabels: directoryItemLabelAdapter.removeMany,
      addDirectoryItemLabels: directoryItemLabelAdapter.addMany,
      setDirectoryItemLabels: directoryItemLabelAdapter.setMany,
      upsertDirectoryItemLabels: directoryItemLabelAdapter.upsertMany,
      upsertDirectoryItemLabel: directoryItemLabelAdapter.upsertOne,
      updateDirectoryItemLabels: directoryItemLabelAdapter.updateMany,
      updateDirectoryItemLabel: directoryItemLabelAdapter.updateOne,
      removeAllDirectoryItemLabels: directoryItemLabelAdapter.removeAll,
      updateDirectoryItemLabelLabel: (state, action) => {
         const { id, label } = action.payload;
         const existingItem = state.entities[id];
         if (existingItem) {
            existingItem.label = label;
         }
      },
      updateDirectoryItemLabelKey: (state, action) => {
         const { id, key, keyofParent } = action.payload;
         const existingItem = state.entities[id];
         if (existingItem) {
            existingItem.key = key;
            existingItem.keyofParent = keyofParent;
         }
      }
   }
});

export const directoryItemLabelActions = directoryItemLabelSlice.actions;

export const selectDirectoryItemLabelsByItemId = createSelector(
   (state: IRootState) => selectDirectoryItemLabelEntities(state),
   (_: IRootState, itemId?: string) => itemId,
   (directoryItems, itemId) => {
      if (!itemId) return [];
      return Object.values(directoryItems).filter((item) => item?.directoryItemId === itemId);
   }
);

export const selectKeyOfParentLabelsByItemId = createSelector(
   (state: IRootState, itemId?: string) => selectDirectoryItemLabelsByItemId(state, itemId),
   (directoryItems) => {
      return directoryItems.filter((item) => item?.keyofParent === true);
   }
);

export const selectComputedDirectoryItemLabelByItemId = createSelector(
   // (state: IRootState) => selectDirectoryItemLabelEntities(state),
   (state: IRootState) => state,
   (_: IRootState, params: { id: string; value?: any; parentValue?: any }) => params,

   (state, { id, value, parentValue }) => {
      const labels = selectDirectoryItemLabelsByItemId(state, id);
      const item = selectDirectoryItemById(state, id);
      let render = "";
      const isList = item?.query !== undefined;
      const itemLabels = Object.values(labels).filter((label) => label?.directoryItemId === item?.id);

      itemLabels.forEach((entry) => {
         if (entry.label) {
            render += entry.label;
         } else if (entry.key) {
            if (entry.keyofParent && parentValue) {
               const s = parentValue[entry.key];
               render += s;
            } else if (value) {
               const s = value[entry.key as keyof any];
               render += s;
            }
         }
      });
      if (render === "") {
         if (isList) {
            render = "New List";
         } else {
            render = "New Drawer";
         }
      }
      return render;
   }
);
export const {
   selectAll: selectAllDirectoryItemLabels,
   selectById: selectDirectoryItemLabelById,
   selectIds: selectDirectoryItemLabelIds,
   selectEntities: selectDirectoryItemLabelEntities
} = directoryItemLabelAdapter.getSelectors<IRootState>((state) => state.directoryItemLabel);
