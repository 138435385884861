import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import ILog from "app/_lib/global/Log";
import { DateTime } from "luxon";
import { apiActivitySession } from "../../../(platform)/(authentication)/_api/activitySession";
import { TAGS } from "../api/TAGS";

export const ccListener = createListenerMiddleware();
const isCCAction = isAnyOf(apiActivitySession.endpoints.createActivitySession.matchFulfilled);
ccListener.startListening({
   // actionCreator: isSpecialAction,
   matcher: isCCAction,
   effect: async (action, listenerApi) => {
      const payload = action.payload;

      // listenerApi.cancelActiveListeners();
      // if(await listenerApi.condition()){}
      if (payload.session?.expires) {
         const timeout = DateTime.fromMillis(payload.session.expires).diffNow().as("milliseconds") * 0.8;
         // const timeout = 20000;
         ILog.v("listenerMiddleware_timeout_started_CC", { id: payload.id });

         // Use the listener API methods to dispatch, get state,
         // unsubscribe the listener, start child tasks, and more
         //  listenerApi.dispatch(todoAdded("Buy pet food"));
         // Spawn "child tasks" that can do more work and return results
         const task = listenerApi.fork(async (forkApi) => {
            // Can pause execution
            await forkApi.delay(timeout);
            // Complete the child by returning a value
            listenerApi.dispatch(
               apiActivitySession.util.invalidateTags([
                  { type: TAGS.ACTIVITY_SESSION, id: payload.id },
                  { type: TAGS.SESSION_BY_ROLE_ID, id: payload?.commitment_connection_directus_roles[0] }
               ])
            );
            return { status: "ok" };
         });

         const result = await task.result;
         // Unwrap the child result in the listener
         if (result.status === "ok") {
            ILog.v("listenerMiddleware_timeout_started_INVALIDATED_CC", { id: payload.id });
            // Logs the `42` result value that was returned
         }
      }
   }
});
