import { createSelector } from "@reduxjs/toolkit";
import { UserIdContext } from "app/(platform)/(authentication)/_contexts/AuthenticatedSessionProvider";
import useAuthenticatedPublicRoles from "app/(platform)/(authentication)/_hooks/useAuthenticatedPublicRoles";
import { apiWSAuthenticated } from "app/(platform)/@search/_api/apiWSAuthenticated";
import { CampaignContext } from "app/(platform)/_contexts/CampaignContext";
import ILog from "app/_lib/global/Log";
import { calculateCDUQueryDefaults } from "app/_services/redux/api/_helpers/rtkHelpers";
import { PA } from "app/_types/PATypes";
import { CampaignRoleTypes, M } from "app/_types/Schema";
import { useParams } from "next/navigation";
import { useContext, useMemo } from "react";
import { RoleAcronymsReverse } from "./useAllRoleConditionals";
export default function useAllCDURoleQueries({ campaignId }: { campaignId?: string | undefined }): {
   connectionRole: PA.RoleQueries | undefined;
   hostRole: PA.RoleQueries | undefined;
   ownerRole: PA.RoleQueries | undefined;
   campaignManagerRole: PA.RoleQueries | undefined;
   authenticatedRole: PA.RoleQueries | undefined;
   publicRole: PA.RoleQueries | undefined;
} {
   const { campaignId: campaignIdContext } = useContext(CampaignContext);
   const campaignIdParams = useParams().campaign_id as string | undefined;
   const finalCampaignId = campaignId || campaignIdContext || campaignIdParams;
   const { authenticatedRole, publicRole } = useAuthenticatedPublicRoles();

   const connectionRole = useCDURoleQuery({ campaignId: finalCampaignId, roleType: "connection" });

   const hostRole = useCDURoleQuery({ campaignId: finalCampaignId, roleType: "host" });

   const campaignManagerRole = useCDURoleQuery({ campaignId: finalCampaignId, roleType: "campaign_manager" });

   const ownerRole = useCDURoleQuery({ campaignId: finalCampaignId, roleType: "owner" });

   ILog.v("useAllCDURoleQueries", { connectionRole, hostRole, ownerRole, campaignManagerRole });
   return {
      connectionRole: connectionRole as PA.RoleQueries | undefined,
      hostRole: hostRole as PA.RoleQueries | undefined,
      ownerRole: ownerRole as PA.RoleQueries | undefined,
      campaignManagerRole: campaignManagerRole as PA.RoleQueries | undefined,
      authenticatedRole,
      publicRole
   };
}

function useCDURoleQuery({ campaignId, roleType }: { campaignId: string | undefined; roleType: CampaignRoleTypes }): PA.RoleQueries | undefined {
   const { userId } = useContext(UserIdContext);

   const { authenticatedRole } = useAuthenticatedPublicRoles();

   const selectCDU = useMemo(() => {
      return createSelector(
         (res) => res.data,
         (_, campaignId) => campaignId,
         (data: M.CampaignDirectusUser[], campaignId) => {
            ILog.v("selectCDU", { data, campaignId });
            return (
               data?.filter(
                  (cdu) =>
                     cdu.campaign_directus_user_campaign === campaignId &&
                     cdu.campaign_directus_user_role === roleType &&
                     cdu.campaign_directus_user_user === userId &&
                     cdu.campaign_directus_user_void !== true
               ) ?? []
            );
         }
      );
   }, [campaignId, roleType, userId]);

   const { roleCDU, currentData, status } = apiWSAuthenticated.useMyCDUQuery(
      {
         role: authenticatedRole
      },
      {
         selectFromResult: (result) => ({
            ...result,
            roleCDU: selectCDU(result, campaignId)
         }),
         skip: !campaignId || !roleType || !authenticatedRole || !userId
      }
   );

   const query = useMemo(() => {
      if (campaignId && userId && roleType) {
         return calculateCDUQueryDefaults({ campaignId, userId, roleType });
      }
      return undefined;
   }, [campaignId, userId, roleType]);

   const role = useMemo(() => {
      if (!authenticatedRole) return undefined;
      if (roleCDU && roleCDU.length > 0 && roleType && query) {
         return { enumKey: RoleAcronymsReverse[roleType as keyof typeof RoleAcronymsReverse], type: roleType, CDU: query, roleId: roleCDU[0].campaign_directus_user_directus_roles as string };
      }
      return undefined;
   }, [roleCDU && roleCDU?.length, roleType, query, authenticatedRole]);
   ILog.v("useCDURoleQuery", { roleCDU, campaignId, roleType, selectCDU, role, query, currentData, status });

   return role;
}

export function useSelectAllCDURoleOfType({ roleType }: { roleType: CampaignRoleTypes }): PA.RoleQueries[] {
   const { userId } = useContext(UserIdContext);

   const { authenticatedRole } = useAuthenticatedPublicRoles();
   const selectCDUs = useMemo(() => {
      return createSelector(
         (res) => res.data,
         (_, roleType) => roleType,
         (data: M.CampaignDirectusUser[], roleType) => {
            ILog.v("selectCDU", { data, roleType });
            return data?.filter((cdu) => cdu.campaign_directus_user_role === roleType && cdu.campaign_directus_user_void !== true) ?? [];
         }
      );
   }, [roleType]);
   const {
      roleCDU: roleCDUs,
      currentData,
      status
   } = apiWSAuthenticated.useMyCDUQuery(
      {
         role: authenticatedRole
      },
      {
         selectFromResult: (result) => ({
            ...result,
            roleCDU: selectCDUs(result, roleType)
         }),
         skip: !roleType || !authenticatedRole || !userId
      }
   );

   const roles = useMemo(() => {
      if (!authenticatedRole) return [];
      if (roleCDUs && roleCDUs.length > 0 && roleType) {
         return roleCDUs.map((cdu) => {
            const query = calculateCDUQueryDefaults({ campaignId: cdu.campaign_directus_user_campaign as string, userId, roleType });
            return { enumKey: RoleAcronymsReverse[roleType as keyof typeof RoleAcronymsReverse], type: roleType, CDU: query, roleId: cdu.campaign_directus_user_directus_roles as string };
         });
      }
      return [];
   }, [roleCDUs && roleCDUs?.length, roleType, authenticatedRole]);

   return roles;
}
