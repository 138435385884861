import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { M } from "app/_types/Schema";
import { selectDirectoryConfigurationName } from "./directoryConfiguration";
import { IRootState } from "app/_contexts/ReduxProvider";

const accordionItemAdapter = createEntityAdapter({
   selectId: (accordionItem: M.AccordionItem) => accordionItem.id
   // sortComparer: (a, b) => a.id - b.id
});

const initialState = accordionItemAdapter.getInitialState({
   status: "idle",
   error: null
});

export const accordionItemSlice = createSlice({
   name: "accordionItem",
   initialState,
   reducers: {
      addAccordionItem: accordionItemAdapter.addOne,
      removeAccordionItem: accordionItemAdapter.removeOne,
      removeAccordionItems: accordionItemAdapter.removeMany,
      addAccordionItems: accordionItemAdapter.addMany,
      setAccordionItems: accordionItemAdapter.setMany,
      upsertAccordionItems: accordionItemAdapter.upsertMany,
      upsertAccordionItem: accordionItemAdapter.upsertOne,
      updateAccordionItems: accordionItemAdapter.updateMany,
      updateAccordionItem: accordionItemAdapter.updateOne,
      removeAllAccordionItems: accordionItemAdapter.removeAll
   }
});

export const accordionItemActions = accordionItemSlice.actions;

export const selectAccordionItemChildrenByParentId = createSelector(
   (state: IRootState) => selectAllAccordionItems(state),
   (state: IRootState) => selectDirectoryConfigurationName(state),
   (_: IRootState, itemId?: string) => itemId,
   (accordionItems, selectedDirectoryConfigurationName, itemId) => {
      if (!itemId || !selectedDirectoryConfigurationName) return [];
      return Object.values(accordionItems).filter((item) => item?.parentIndex === itemId && item.id.includes(selectedDirectoryConfigurationName));
   }
);

export const {
   selectAll: selectAllAccordionItems,
   selectById: selectAccordionItemById,
   selectIds: selectAccordionItemIds,
   selectEntities: selectAccordionItemEntities
} = accordionItemAdapter.getSelectors<IRootState>((state) => state.accordionItem);
