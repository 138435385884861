import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { apiAnySession } from "app/(platform)/(authentication)/_api/anySession";
import ILog from "app/_lib/global/Log";
import { PublicEnv } from "app/_lib/global/PublicEnv";
import { DateTime } from "luxon";
import { TAGS } from "../api/TAGS";

export const anySessionListener = createListenerMiddleware();

// export type AppStartListening = TypedStartListening<IRootState, AppDispatch>;

// export const startAppListening = listenerMiddleware.startListening as AppStartListening;

// export const addAppListener = addListener as TypedAddListener<IRootState, AppDispatch>;
const isAnyAction = isAnyOf(apiAnySession.endpoints.getSession.matchFulfilled);

anySessionListener.startListening({
   // actionCreator: isSpecialAction,
   matcher: isAnyAction,
   effect: async (action, listenerApi) => {
      const payload = action.payload;

      const parsePayload = () => {
         if (payload?.authenticatedSession?.expires) {
            return { expires: payload.authenticatedSession.expires, roleId: PublicEnv.DirectusAuthenticatedRoleId! };
         }
         if (payload?.cduSession?.session?.expires) {
            return { expires: payload.cduSession?.session?.expires, roleId: payload.ccSession?.commitment_connection_directus_roles[0] };
         }
         if (payload?.ccSession?.session?.expires) {
            return { expires: payload?.ccSession?.session?.expires, roleId: payload.cduSession?.campaign_directus_user_directus_roles as string };
         }

         ILog.w("listenerMiddleware_no_session_in_payload", { action, payload });

         return { expires: null, roleId: "public" };
      };
      const { expires, roleId } = parsePayload();
      // listenerApi.cancelActiveListeners();

      // if(await listenerApi.condition()){}
      if (expires) {
         const timeout = DateTime.fromMillis(expires).diffNow().as("milliseconds") * 0.8;
         // const timeout = 20000;
         ILog.v("listenerMiddleware_timeout_started_ANY", { action, payload, timeout });

         // Use the listener API methods to dispatch, get state,
         // unsubscribe the listener, start child tasks, and more
         //  listenerApi.dispatch(todoAdded("Buy pet food"));

         // Spawn "child tasks" that can do more work and return results
         const task = listenerApi.fork(async (forkApi) => {
            // Can pause execution
            await forkApi.delay(timeout);
            // Complete the child by returning a value

            listenerApi.dispatch(
               apiAnySession.util.invalidateTags([
                  // { type: TAGS.ANY_SESSION, id: "LIST" },
                  { type: TAGS.ANY_SESSION, id: payload.token },
                  { type: TAGS.SESSION_BY_ROLE_ID, id: roleId }
               ])
            );
            return { status: "ok" };
         });

         const result = await task.result;
         // Unwrap the child result in the listener
         if (result.status === "ok") {
            ILog.v("listenerMiddleware_timeout_started_INVALIDATED_ANY", { action, payload, timeout });
            // Logs the `42` result value that was returned
         }
      }
   }
});
