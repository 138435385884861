import { PA } from "app/_types/PATypes";
import { Sessions } from "app/_types/Session";
import { TAGS } from "../../../_services/redux/api/TAGS";
import { handleRTKError, handleRTKTags } from "../../../_services/redux/api/_helpers/rtkHelpers";
import { apiService } from "../../../_services/redux/api/apiService";
import { getMySession } from "../_helpers/getMySession";

export const apiAnySession = apiService.injectEndpoints({
   endpoints: (builder) => ({
      getSession: builder.query<
         {
            token: string | undefined;

            authenticatedSession?: Sessions.Authenticated.Session;
            ccSession?: Sessions.Activities.CCSession;
            cduSession?: Sessions.Campaign.CDUSession;
         },
         {
            role: PA.RoleQueries | undefined;
            adminTokenOverride?: string;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const res = await getMySession({ role: arg.role, tokenOverride: arg.adminTokenOverride, dispatch: api.dispatch });
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "apiAnySession" });
            }
         },

         providesTags: (result, error, query) => {
            let tags = [];
            if (result) {
               if (query.role?.enumKey === "a") {
                  tags.push({ type: TAGS.AUTHENTICATED_SESSION, id: "SINGLETON" });
               }
               if (result?.cduSession) {
                  tags.push({ type: TAGS.CDU_SESSION, id: "LIST" });
               }
               if (result?.ccSession) {
                  tags.push({ type: TAGS.CC_SESSION, id: "LIST" });
               }
               tags.push({ type: TAGS.ANY_SESSION, id: "LIST" }, { type: TAGS.ANY_SESSION, id: result.token! });
            } else if (error) {
               if (query.role?.enumKey === "a") {
                  tags.push({ type: TAGS.AUTHENTICATED_ERROR, id: "SINGLETON" });
               } else if (query.role?.enumKey === "c" || query?.role?.enumKey === "cm" || query?.role?.enumKey === "h" || query?.role?.enumKey === "o") {
                  tags.push({ type: TAGS.CDU_ERROR, id: "LIST" });
               } else {
                  tags.push({ type: TAGS.CC_ERROR, id: "LIST" });
               }
            }
            return handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               resultTags: tags
            });
         }
      })
   }),

   overrideExisting: true
});
