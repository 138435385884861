"use client";

import { addListener, combineReducers, configureStore, ListenerEffectAPI, TypedAddListener, TypedStartListening } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { agendaItemSlice } from "app/(platform)/meeting-room/_services/agendaItemSlice";
import { breakoutSlice } from "app/(platform)/meeting-room/_services/breakoutSlice";
import { chatMessageSlice } from "app/(platform)/meeting-room/_services/chatMessageSlice";
import { listeningRequestSlice } from "app/(platform)/meeting-room/_services/listeningRequestSlice";
import { meetingSlice } from "app/(platform)/meeting-room/_services/meetingSlice";
import { roundSlice } from "app/(platform)/meeting-room/_services/roundSlice";
import { turnSlice } from "app/(platform)/meeting-room/_services/turnSlice";
import LoadingPageDefault from "app/_components_v2/feedback/LoadingPageDefault";
import { apiService } from "app/_services/redux/api/apiService";
import { anySessionListener } from "app/_services/redux/listeners/anySessionListener";
import { ccListener } from "app/_services/redux/listeners/ccListener";
import { cduListener } from "app/_services/redux/listeners/cduListener";
import { directoryListener } from "app/_services/redux/listeners/directoryListener";
import { accordionItemSlice } from "app/_services/redux/slices/accordionItemSlice";
import { appSlice } from "app/_services/redux/slices/appSlice";
import { directoryConfigurationSlice } from "app/_services/redux/slices/directoryConfiguration";
import { directoryItemSlice } from "app/_services/redux/slices/directoryItem";
import { directoryItemLabelSlice } from "app/_services/redux/slices/directoryItemLabel";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Provider, TypedUseSelectorHook, useDispatch, useSelector, useStore } from "react-redux";
import { Persistor, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/es/storage";
import { PersistGate } from "redux-persist/integration/react";

export default function ReduxProvider({ children }: { children: React.ReactNode }) {
   const storeRef = useRef<AppStore>();
   const persistorRef = useRef<Persistor>();
   const [loading, setLoading] = useState<boolean>(true);

   const makeStore = useMakeStore({ setLoading });
   if (!storeRef.current) {
      // Create the store instance for the first time this renders
      const store = makeStore();
      storeRef.current = store;
      persistorRef.current = persistStore(store);
   }

   // Any references outside of useEffect will return a ReferenceError,
   // which leads to redux-persist's error.
   useEffect(() => {
      // other client-side logic...
   }, []);

   if (loading || !storeRef.current || !persistorRef.current) {
      return <LoadingPageDefault />;
   }
   return (
      <Provider store={storeRef.current}>
         <PersistGate loading={<LoadingPageDefault />} persistor={persistorRef.current}>
            {children}
         </PersistGate>
      </Provider>
   );
}

function useMakeStore({ setLoading }: { setLoading: (loading: boolean) => void }) {
   return useCallback(() => {
      setLoading(false);
      const persistConfig = {
         key: "root",
         version: 1,
         storage,
         blacklist: [
            apiService.reducerPath,
            agendaItemSlice.reducerPath,
            breakoutSlice.reducerPath,
            chatMessageSlice.reducerPath,
            listeningRequestSlice.reducerPath,
            meetingSlice.reducerPath,
            roundSlice.reducerPath,
            turnSlice.reducerPath,
            appSlice.reducerPath
         ],
         whitelist: [directoryConfigurationSlice.reducerPath, directoryItemSlice.reducerPath, directoryItemLabelSlice.reducerPath, accordionItemSlice.reducerPath],
         timeout: 3000
      };

      const rootReducer = combineReducers({
         // meetingStore
         app: appSlice.reducer,
         agendaItem: agendaItemSlice.reducer,
         breakout: breakoutSlice.reducer,
         chatMessage: chatMessageSlice.reducer,
         listeningRequest: listeningRequestSlice.reducer,
         meeting: meetingSlice.reducer,
         round: roundSlice.reducer,
         turn: turnSlice.reducer,
         directoryConfiguration: directoryConfigurationSlice.reducer,
         directoryItem: directoryItemSlice.reducer,
         directoryItemLabel: directoryItemLabelSlice.reducer,
         accordionItem: accordionItemSlice.reducer,
         [apiService.reducerPath]: apiService.reducer
      });
      const persistedReducer = persistReducer(persistConfig, rootReducer);

      const store = configureStore({
         // devTools: {
         //    name: v4()
         // shouldHotReload: false
         // },
         reducer: persistedReducer,
         middleware: (gDM) =>
            gDM({
               // For persist:
               // serializableCheck: {
               //    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
               // }
               serializableCheck: false,
               immutableCheck: false
            })
               .prepend(cduListener.middleware, ccListener.middleware, anySessionListener.middleware, directoryListener.middleware)
               .concat(apiService.middleware),
         enhancers: (getDefaultEnhancers) =>
            getDefaultEnhancers({
               autoBatch: true
            })
      });
      setupListeners(store.dispatch);
      return store;
   }, []);
}
export type AppStore = ReturnType<ReturnType<typeof useMakeStore>>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type IRootState = ReturnType<AppStore["getState"]>;
// @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
export type AppDispatch = AppStore["dispatch"];

export type AppListenerEffectAPI = ListenerEffectAPI<IRootState, AppDispatch>;

// @see https://redux-toolkit.js.org/api/createListenerMiddleware#typescript-usage
export type AppStartListening = TypedStartListening<IRootState, AppDispatch>;
export type AppAddListener = TypedAddListener<IRootState, AppDispatch>;

// export const startAppListening = listenerMiddlewareInstance.startListening as AppStartListening;
export const addAppListener = addListener as AppAddListener;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;

export const useAppStore = useStore.withTypes<AppStore>();
