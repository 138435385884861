"use client";
import { M } from "app/_types/Schema";
import { createContext } from "react";

export const CampaignContext = createContext<{
   campaign: M.Campaign | undefined;
   campaignId: string | undefined;
}>({
   campaign: undefined,
   campaignId: undefined
});
