import React from "react";
import Squares from "react-activity/dist/Squares";
import "react-activity/dist/Squares.css";
import Header from "../data-display/Header";
interface LoadingProps {
   className?: string;
   message?: string;
   isSettled?: boolean;
   settledMessage?: string;
}

const LoadingPageDefault: React.FC<LoadingProps> = ({ className = "", message, isSettled = false, settledMessage = "This resource does not exist, or it is not shared with you." }) => {
   return (
      <div className="flex w-full h-screen place-items-center place-content-center max-w-[1200px] mx-auto">
         <div className="flex flex-col gap-10">
            {!isSettled && <Squares color="#727981" size={32} speed={0.5} animating={true} />}
            {message && !isSettled && <Header label={message} level="h2" className="text-slate-900" />}
            {isSettled && <Header label={settledMessage} level="h2" />}
         </div>
      </div>
   );
};

export default LoadingPageDefault;
