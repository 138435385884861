import { PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { IRootState } from "app/_contexts/ReduxProvider";
import { M } from "app/_types/Schema";

const directoryItemAdapter = createEntityAdapter({
   selectId: (directoryItem: M.DirectoryItem) => directoryItem.id
   // sortComparer: (a, b) => a.id - b.id
});

const initialState = directoryItemAdapter.getInitialState({
   status: "idle",
   error: null
});

export const directoryItemSlice = createSlice({
   name: "directoryItem",
   initialState,
   reducers: {
      addDirectoryItem: directoryItemAdapter.addOne,
      removeDirectoryItem: directoryItemAdapter.removeOne,
      removeDirectoryItems: directoryItemAdapter.removeMany,
      addDirectoryItems: directoryItemAdapter.addMany,
      setDirectoryItems: directoryItemAdapter.setMany,
      upsertDirectoryItems: directoryItemAdapter.upsertMany,
      upsertDirectoryItem: directoryItemAdapter.upsertOne,
      updateDirectoryItems: directoryItemAdapter.updateMany,
      updateDirectoryItem: directoryItemAdapter.updateOne,
      removeAllDirectoryItems: directoryItemAdapter.removeAll,
      addChildItems: (state, action: PayloadAction<{ id: string; items: string[] }>) => {
         const { id, items } = action.payload;
         const existingItem = state.entities[id];

         if (existingItem) {
            const { items: existingItems } = existingItem;
            const newItems = items.filter((argsI) => !existingItems.find((ei) => ei === argsI));
            existingItem.items = [...existingItems, ...newItems];
         }
      },
      removeChildItems: (state, action: PayloadAction<{ id: string; items: string[] }>) => {
         const { id, items } = action.payload;
         const existingItem = state.entities[id];
         if (existingItem) {
            const { items: existingItems } = existingItem;
            const newItems = existingItems.filter((argsI) => !items.find((ei) => ei === argsI));
            existingItem.items = newItems;
         }
      }
   }
});

export const directoryItemActions = directoryItemSlice.actions;

export const {
   selectAll: selectAllDirectoryItems,
   selectById: selectDirectoryItemById,
   selectIds: selectDirectoryItemIds,
   selectEntities: selectDirectoryItemEntities
} = directoryItemAdapter.getSelectors<IRootState>((state) => state.directoryItem);
