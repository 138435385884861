"use client";
import ILog from "app/_lib/global/Log";
import { M } from "app/_types/Schema";
import { createContext, useMemo } from "react";
import useAuth from "../_hooks/useAuth";

export default function AuthenticatedSessionProvider(props: { children: React.ReactNode }) {
   const { children } = props;

   const { status, authenticatedSession, first_name, last_name, fullName } = useAuth({
      required: false // This will become a query param
   });
   const statusValue = useMemo(() => {
      return { status: status, isAuthenticated: status === "authenticated" };
   }, [status]);
   const tokenValue = useMemo(() => {
      return { token: authenticatedSession?.access_token };
   }, [authenticatedSession?.access_token]);
   const localUserValue = useMemo(() => {
      return { user: authenticatedSession?.user };
   }, [authenticatedSession?.user]);
   // const { first_name, last_name } = useMemo(() => {
   //    return { first_name: first_name, last_name: last_name };
   // }, [first_name, last_name]);
   const userIdValue = useMemo(() => {
      return { userId: authenticatedSession?.user?.id };
   }, [authenticatedSession?.user?.id]);
   const metadataValue = useMemo(() => {
      return {
         refresh_token: authenticatedSession?.refresh_token,
         expires: authenticatedSession?.expires,
         error: authenticatedSession?.error,
         buildId: authenticatedSession?.buildId,
         reloadRequired: authenticatedSession?.reloadRequired
      };
   }, [authenticatedSession?.refresh_token, authenticatedSession?.expires, authenticatedSession?.error, authenticatedSession?.buildId, authenticatedSession?.reloadRequired]);
   ILog.v("AuthenticatedSessionProvider", { statusValue, tokenValue, localUserValue, first_name, last_name, userIdValue, metadataValue });
   return (
      <AuthStatusContext.Provider value={statusValue}>
         <TokenContext.Provider value={tokenValue}>
            <LocalUserContext.Provider value={localUserValue}>
               <LocalUserNameContext.Provider value={{ first_name, last_name, fullName }}>
                  <UserIdContext.Provider value={userIdValue}>
                     <AuthenticatedSessionMetadataContext.Provider value={metadataValue}>{children}</AuthenticatedSessionMetadataContext.Provider>
                  </UserIdContext.Provider>
               </LocalUserNameContext.Provider>
            </LocalUserContext.Provider>
         </TokenContext.Provider>
      </AuthStatusContext.Provider>
   );
}

// export type AuthenticatedSessionContextType = {

//    authenticatedSession: Sessions.Authenticated.Session | undefined;

// };

// export const AuthenticatedSessionContext = createContext<AuthenticatedSessionContextType>({

//    authenticatedSession: undefined
// });

export type AuthStatusContextType = {
   status: "authenticated" | "loading" | "unauthenticated" | undefined;
   isAuthenticated: boolean;
};

export const AuthStatusContext = createContext<AuthStatusContextType>({
   status: undefined,
   isAuthenticated: false
});

export type UserIdContextType = {
   userId: M.DirectusUsers["id"] | undefined;
};

export const UserIdContext = createContext<UserIdContextType>({
   userId: undefined
});

export type LocalUserContextType = {
   user: M.DirectusUsers | undefined;
};

export const LocalUserContext = createContext<LocalUserContextType>({
   user: undefined
});

export type LocalUserNameContextType = {
   first_name: string | undefined | null;
   last_name: string | undefined | null;
   fullName: string | undefined;
};

export const LocalUserNameContext = createContext<LocalUserNameContextType>({
   first_name: undefined,
   last_name: undefined,
   fullName: undefined
});

export type AuthenticatedSessionMetadataContextType = {
   refresh_token: string | undefined;
   expires: number | undefined;
   error?: string;
   buildId?: string;
   reloadRequired?: boolean;
};

export const AuthenticatedSessionMetadataContext = createContext<AuthenticatedSessionMetadataContextType>({
   refresh_token: undefined,
   expires: undefined,
   error: undefined,
   buildId: undefined,
   reloadRequired: undefined
});

export type TokenContextType = {
   token: string | undefined;
};

export const TokenContext = createContext<TokenContextType>({
   token: undefined
});
