import { PublicEnv } from "app/_lib/global/PublicEnv";
import { PA } from "app/_types/PATypes";
import { useContext, useMemo } from "react";
import { AuthStatusContext } from "../_contexts/AuthenticatedSessionProvider";
export default function useAuthenticatedPublicRoles(): {
   publicRole: PA.RoleQueries;
   authenticatedRole: PA.RoleQueries | undefined;
} {
   const { status } = useContext(AuthStatusContext);

   const authenticatedRole = useMemo(() => {
      if (status === "authenticated") {
         return calculateAuthenticatedRole();
      }
      return undefined;
   }, [status]);
   const publicRole = useMemo(() => {
      return calculatePublicRole();
   }, []);
   return {
      publicRole,
      authenticatedRole
   };
}

export function calculateAuthenticatedRole() {
   return { enumKey: "a", type: "authenticated", Authenticated: true, roleId: PublicEnv.DirectusAuthenticatedRoleId! } as PA.RoleQueries;
}

export function calculatePublicRole() {
   return { enumKey: "p", type: "public", Public: true, roleId: "public" } as PA.RoleQueries;
}
