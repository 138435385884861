import ILog from "app/_lib/global/Log";
import { toast } from "react-toastify";
import { handleRTKError, handleRTKTags } from "./_helpers/rtkHelpers";
import { apiService } from "./apiService";

export type NextServerActionFunction<T = unknown> = () => Promise<NextServerAction<T>>;
export type NextServerAction<T = unknown> = {
   invalidatedTags?: {
      type: string;
      id: string;
   }[];
   providesTags?: {
      type: string;
      id: string;
   }[];

   status: number;
   data?: T;
   error?: string;
};

export const apiNext = apiService.injectEndpoints({
   endpoints: (builder) => ({
      action: builder.mutation<Awaited<NextServerAction>, NextServerActionFunction>({
         queryFn: async (arg, api) => {
            try {
               const res = await arg();
               if (res.status !== 200) throw new Error(res.error);
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "apiNext.useActionMutation" });
            }
         },
         invalidatesTags: (result, error, query) => {
            ILog.v("apiNext.action.invalidatesTags", { result, error, query });

            // if (error) {
            //    //@ts-expect-error
            //    toast.error(`${error.error}`, { autoClose: 3000 });
            // }
            return handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               resultTags: result?.invalidatedTags || []
            });
         }
      }),
      actionWithArguments: builder.mutation<Awaited<NextServerAction>, { func: Function; args: any }>({
         queryFn: async (arg, api) => {
            try {
               const res = await arg.func(arg.args);

               ILog.v("apiNext.actionWithArguments", { data: res as Awaited<NextServerAction> });

               return { data: res as Awaited<NextServerAction> };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "actionWithArguments" });
            }
         },
         invalidatesTags: (result, error, query) => {
            ILog.v("apiNext.action.invalidatesTags", { result, error, query });
            //@ts-expect-error
            toast.error(`${error.error}`, { autoClose: 3000 });
            return handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               resultTags: result?.invalidatedTags || []
            });
         }
      })
   }),
   overrideExisting: true
});
