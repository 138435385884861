import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import ILog from "app/_lib/global/Log";
import { DateTime } from "luxon";
import { apiCampaignSession } from "../../../(platform)/(authentication)/_api/campaignSession";
import { TAGS } from "../api/TAGS";

export const cduListener = createListenerMiddleware();

// export type AppStartListening = TypedStartListening<IRootState, AppDispatch>;

// export const startAppListening = listenerMiddleware.startListening as AppStartListening;

// export const addAppListener = addListener as TypedAddListener<IRootState, AppDispatch>;

const isCDUAction = isAnyOf(apiCampaignSession.endpoints.createCampaignSession.matchFulfilled);

cduListener.startListening({
   // actionCreator: isSpecialAction,
   matcher: isCDUAction,
   effect: async (action, listenerApi) => {
      const payload = action.payload;
      const query = action.meta.arg.originalArgs.query;

      if (payload.session?.expires) {
         const timeout = DateTime.fromMillis(payload.session.expires).diffNow().as("milliseconds") * 0.8;
         ILog.v("listenerMiddleware_timeout_started_CDU", { action, payload, timeout });

         const task = listenerApi.fork(async (forkApi) => {
            await forkApi.delay(timeout);

            listenerApi.dispatch(
               apiCampaignSession.util.invalidateTags([
                  { type: TAGS.CAMPAIGN_SESSION, id: "LIST" },
                  { type: TAGS.CAMPAIGN_SESSION, id: payload.id },
                  { type: TAGS.SESSION_BY_ROLE_ID, id: payload?.campaign_directus_user_directus_roles as string }
               ])
            );
            return { status: "ok" };
         });

         const result = await task.result;
         if (result.status === "ok") {
            ILog.v("listenerMiddleware_timeout_started_INVALIDATED_CDU", { action, payload, timeout });
         }
      }
   }
});
